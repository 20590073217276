<template>
  <v-layout align-center justify-center>
    <v-flex xs12 lg10>
      <v-layout column justify-center class="mb-4">
        <h3 class="logo">id</h3>
        <span class="project">personal</span>
      </v-layout>
      <v-layout column>
        <v-flex xs12>
          <v-card flat :disabled="loading">
            <v-form @submit.prevent="login">
              <validation-observer ref="form">
                <validation-provider name="email" rules="required" v-slot="{ errors }">
                  <v-text-field
                    @keydown.enter="focusPassword"
                    v-model.trim="formData.username"
                    :error-messages="errors"
                    :label="$t('auth.email')"
                    :disabled="loading"
                    color="tertiary"
                    autocomplete
                    autofocus
                    outlined
                    clearable
                    class="mb-3"
                  />
                </validation-provider>
                <validation-provider name="password" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model.trim="formData.password"
                    clearable
                    outlined
                    ref="password"
                    color="tertiary"
                    autocomplete="current-password"
                    :type="showPassword ? 'text' : 'password'"
                    :disabled="loading"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :error-messages="errors"
                    :label="$t('auth.password')"
                    @keydown.enter="login"
                    @click:append="showPassword = !showPassword"
                  />
                </validation-provider>
              </validation-observer>
              <vue-recaptcha
                class=" mb-3"
                ref="recaptcha"
                @verify="onVerify"
                @expired="onExpired"
                @error="onError"
                loadRecaptchaScript
                :sitekey="sitekey"
              >
              </vue-recaptcha>

              <v-btn
                :loading="loading"
                :disabled="loading || !formData.captcha"
                color="primary"
                block
                large
                depressed
                class="mt-5"
                @click.native="loader = 'loading'"
                @click.stop="login"
              >
                {{ $t("auth.sign_in") }}
              </v-btn>

              <v-btn class="mt-5" color="primary" block large depressed @click.stop="goAzure">
                AZURE AD
              </v-btn>
            </v-form>
          </v-card>
        </v-flex>

        <v-flex xs12 class="mt-3">
          <v-btn block text :disabled="loading" depressed :to="{ name: 'forgot' }">
            {{ $t("auth.forget_password") }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description login Component Logic
   * @date 24.07.2020
   */

  import VueRecaptcha from "vue-recaptcha";
  import { mapGetters } from "vuex";
  import { PENDING_FEEDBACKS } from "./query";

  export default {
    name: "login",
    props: {
      redirect: String
    },
    components: {
      "vue-recaptcha": VueRecaptcha
    },
    data() {
      return {
        formData: {
          username: null,
          password: null,
          captcha: null
        },
        showPassword: false,
        sitekey: process.env.VUE_APP_RECAPTCHA_KEY
      };
    },
    computed: {
      ...mapGetters({
        loading: "auth/loading"
      })
    },

    methods: {
      azureRoute() {
        let azureCompleted = localStorage.getItem("azureLoginCompleted");
        if (azureCompleted) azureCompleted = JSON.parse(azureCompleted);
        if (azureCompleted) {
          localStorage.setItem("azureLoginCompleted", JSON.stringify(false));
          this.$router.go();
        }
      },
      goAzure() {
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth
          ? window.innerWidth
          : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width;
        const height = window.innerHeight
          ? window.innerHeight
          : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - 600) / 2 / systemZoom + dualScreenLeft;
        const top = (height - 600) / 2 / systemZoom + dualScreenTop;
        const params = `scrollbars=no,width=600,height=600,left=500,top=250`;
        const azureWindow = window.open(
          "https://login.microsoftonline.com/61cea4b1-8a5a-402a-a97b-23d6b1de63fc/oauth2/v2.0/authorize?client_id=05bb051b-c43c-43aa-a015-58323aab9605&response_type=token&redirect_uri=https://myaffidea.idenfit.com/auth/login-azure&response_mode=fragment&state=12345&scope=https%3A%2F%2Fgraph.microsoft.com%2Fuser.read",
          "test",
          `scrollbars=yes,width=${600 / systemZoom},height=${600 / systemZoom},top=${top},left=${left}`
        );
        if (window.focus) azureWindow.focus();
        this.azureTimer = setInterval(() => {
          this.azureRoute();
        }, 333);
      },
      login() {
        this.$refs.form.validate().then(valid => {
          if (valid) {
            if (!this.formData.captcha) {
              this.$helpers.showNotification("robot_validation", "error");
              return false;
            }
            this.$store
              .dispatch("auth/LOGIN", this.formData)
              .then(firstLogin => {
                // İlk defa kayıt olmuşsa şifre güncellemesi için yeni şifre tanımlama ekranına yönlendiriyoruz.
                this.$store
                  .dispatch("auth/FETCH_MOBILE_SETTINGS")
                  .then()
                  .catch(console.error);
                this.$store
                  .dispatch("auth/FETCH_PENDING_FEEDBACKS")
                  .then(pendingFeedbacks => {
                    if (firstLogin) {
                      this.$router.replace({ name: "first_time" });
                    } else if (pendingFeedbacks.length > 0) {
                      this.$router.replace({ name: "pending_feedbacks" });
                    } else if (this.$route.params.redirect) {
                      this.$router.push(this.$route.params.redirect);
                    } else {
                      this.$router.replace({ name: "dashboard" });
                    }
                  })
                  .catch(console.error);
              })
              .catch(error => this.$helpers.showNotification(this.$t("auth.invalid_email_or_password"), "error", true));
          }
        });
      },
      focusPassword() {
        this.$nextTick(() => this.$refs.password.focus());
      },

      onVerify(response) {
        this.formData.captcha = response;
      },
      onExpired() {
        this.$refs.recaptcha.reset();
      },
      onError() {
        this.$refs.recaptcha.reset();
      }
    }
  };
</script>

<style scoped>
  .logo {
    font-family: "Paytone One", sans-serif;
    font-size: 100px;
    text-align: center;
    display: block;
    color: var(--v-primary-base);
  }

  .project {
    font-family: "Quicksand", sans-serif;
    font-size: 26px;
    color: var(--v-primary-base);
    text-align: center;
    margin-top: -30px;
  }
</style>
